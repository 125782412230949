<template>
    <div v-if="data" id="headers" class="fixed top-0 left-0 w-full block mb-0" :class="getHeaderClass()" style="z-index: 10;">
        <headers-component :item="data" :transition="transition" />
        <sidebar />
    </div>
</template>
<script>
export default {
    data () {
        return {
            transition: false
        }
    },
    components: {
        HeadersComponent: () => import('./components'),
        Sidebar: () => import('./components/sidebar')
    },
    computed: {
        data () {
            return this.company.Header
        },
        headerActive () {
            return this.$headerActive.value
        }
    },
    watch: {
        headerActive () {
            this.transition = this.headerActive
        }
    },
    methods: {
        getHeaderClass () {
            if (this.windowWidth < 600) {
                if (this.transition) return this.data.ClassTransitionMobile
                else return this.data.ClassMobile
            } else {
                if (this.transition) return this.data.ClassTransition
                else return this.data.Class
            }
        },
        windowScroll () {
            if (window.scrollY > 100) {
                this.transition = true
            } else {
                if (!this.headerActive) this.transition = false
            }
        }
    },
    mounted () {
        this.transition = this.headerActive
    },
    created () {
        document.addEventListener('scroll', this.windowScroll)
    },
    beforeDestroy () {
        document.removeEventListener('scroll', this.windowScroll, false)
    }
}
</script>
